import PropTypes from 'prop-types';

import '../styles/reset.css';
import '../styles/main.css';

const Layout = ({ children }) => children;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
