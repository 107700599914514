import React from 'react';
import { Link } from 'gatsby'

import Head from '../components/head';
import Layout from '../components/layout';

import imgV from '../images/v.svg';

import '../styles/reset.css';
import '../styles/main.css';

const SuccessPage = () => (
  <Layout>
    <Head/>
    <div className="success-container">
      <img src={imgV} alt=""/>
        <h1>Thank You</h1>
        <h2>
          Your massage has been sent
          we will call you back soon
        </h2>
        <Link to="/">Move to main page</Link>
    </div>
  </Layout>
);

export default SuccessPage
